@import 'Variables.scss';

.Page {
  .cover {
    padding: 30px;
    text-align: center;
    margin-top: 50px;
    border-bottom: 2px solid $colorPrimary;
    margin-bottom: 50px;
  }
  .content {
    width: 60%;
    margin: 0 auto;
    padding: 10px;
    border-left: 2px solid $colorPrimary;
    border-right: 2px solid $colorPrimary;
    li {
      margin: 20px;
    }
    a {
      text-decoration: none;
      color: $colorPrimary;
      &:hover {
        font-weight: bold;
        color: $yellow;
      }
    }
    &.content-cookies h3 {
      margin: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Page {
    .cover {
      padding-top: 50px;
    }
    .content {
      width: 90%;
      margin-bottom: 200px;
    }
  }
}
