@import 'Variables.scss';

#home {
  .contentHome {
    width: 100%;
    height: 100vh;
    padding: 5vh 0;
      background-color: $colorPrimary;
    .mouse {
    	position: absolute;
    	width: 22px;
    	height: 42px;
    	bottom: 120px;
    	left: 50%;
    	margin-left: -12px;
    	border-radius: 15px;
    	border: 2px solid $colorPrimary;
    	animation: intro 1.5s;
    	z-index: 999;
    	cursor: pointer;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    }
    .scroll {
    	display: block;
    	width: 3px;
    	height: 3px;
    	margin: 6px auto;
    	border-radius: 4px;
    	background: $colorPrimary;
    	animation: finger 1.5s infinite;
    }
    @keyframes intro {
    	0% {
    		opacity: 0;
    		transform: translateY(40px);
    	}
    	100% {
    		opacity: 1;
    		transform: translateY(0);
    	}
    }
    @keyframes finger {
    	0% {
    		opacity: 1;
    	}
    	100% {
    		opacity: 0;
    		transform: translateY(20px);
    	}
    }
  }
  .contentLogo {
    width: 200px;
    margin: 0 auto;
    margin-top: 20vh;
  }
  .help-scroll {
  	position: absolute;
  	bottom: 100px;
  	left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  	font-size: 20px;
  	z-index: 999;
  	cursor: pointer;
    color: $white;
    width: 330px;
  }
  .contentHomeOne {
    background-image: url("../../public/img/zohu.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .textFirstBlock {
      width: 500px;
      margin: 0 auto;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 10px;
      p {
        text-align: center;
      }
    }
  }
  .contentRow {
    display: flex;
  }
  .col50 {
    width: 50%;
    height: 100vh;
    background-color: $white
  }
  .col100 {
    width: 100%;
    height: 100vh;
    background-color: $white;
    p {
      color: $colorPrimary;
      text-align: center;
      margin-top: 20vh;
      font-size: 40px;
    }
    &.noHeight {
      height: auto;
      padding-bottom: 50px;
    }
  }
  .backGroundcolorPrimay {
    background-color: $colorPrimary;
    color: $white;
    .text {
      color: $colorPrimary;
      text-align: center;
      margin-top: 20vh;
      font-size: 50px;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;
    }
  }
  .contentBlockOne {
    width: 800px;
    height: 85vh;
    margin: 0 auto;
    padding-top: 15vh;
    background-image: url("../../public/img/gare-ascacchi.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .coppia {
      width: 700px;
    }
  }
  .contentArrow {
    width: 80px;
    margin: 0 auto;
    #moreArrows {
      width: 75px;
      height: 65px;
      cursor: pointer;
      &:hover {
        .arrowWhite {
          fill: $white;
          transition: all .2s ease-out;
          &.arrow-bottom {
            transform: translateY(-18px);
          }
          &.arrow-top {
            transform: translateY(18px);
          }
        }
        .arrowRed {
          fill: $colorPrimary;
          transition: all .2s ease-out;
          &.arrow-bottom {
            transform: translateY(-18px);
          }
          &.arrow-top {
            transform: translateY(18px);
          }
        }
      }
      .arrowWhite {
        fill: $white;
        transition: all .2s ease-out;
        &.arrow-middle {
          opacity: 0.75;
        }
        &.arrow-top {
          opacity: 0.5
        }
      }
      .arrowRed {
        fill: $colorPrimary;
        transition: all .2s ease-out;
        &.arrow-middle {
          opacity: 0.75;
        }
        &.arrow-top {
          opacity: 0.5
        }
      }
    }
  }
  .nPoints {
    .contentPrice {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;
      padding: 20px;
      border-radius: 6px;
      background-color: $colorPrimary;
      color: $white;
      cursor: pointer;
      .disabled {
        text-decoration: line-through;
      }
    }
    .yellow {
      color: $yellow;
      font-weight: bold;
    }
    .valuePointDet {
      display: none;
      &.active {
        display: block;
      }
    }
    .myArrow {
        writing-mode: vertical-lr;
        float: right;
        font-size: 45px;
        cursor: pointer;
        &:hover {
          color: $yellow;
          transform: scale(1.2);
        }
    }
  }
  .contentTextProvvisorio {
    width: 90%;
    margin: 0 auto;
    border: 2px solid $colorPrimary;
    border-radius: 20px;
    padding: 10px;
    h2 {
      color: $colorPrimary;
      text-align: right;
    }
    p {
      font-size: 17px;
      color: black ;
      margin-top: 10px;
      text-align: justify;
      line-height: 1.4;
    }
  }
}

@media only screen and (max-width: 700px) {
  #home {
    .backGroundcolorPrimay {
      .text {
        font-size: 26px;
        -webkit-text-stroke-width: 0px;
        color: $white;
      }
    }
    .col100 {
      p {
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #home {
    .contentTextProvvisorio {
      margin-bottom: 200px;
    }
    .contentHomeOne {
      .textFirstBlock  {
        width: 90%;
      }
    }
    .contentBlockOne {
      width: 100%;
      .coppia {
        width: 200%;
        margin-left: -50px;
      }
    }
    .col50 {
      width: 100%;
      display: none;
      &.backGroundcolorPrimay {
        display: block;
      }
    }
    .nPoints {
      .contentPrice {
        width: 70%;
      }
      .myArrow {
        font-size: 25px;
      }
    }
  }
}
