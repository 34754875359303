@import 'Variables.scss';

.Footer {
  width: 100%;
  background-color: $colorPrimary;
  padding: 3vh 0;
  // display: flex;
  font-size: 15px;
  z-index: 99999;
  .itemsMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      font-weight: bold;
      color: $yellow;
    }
  }
  div {
    cursor: pointer;
    color: $white;
    margin: 0 10px;
    span {
      margin-left: 20px;
    }
    .hover:hover {
      font-weight: bold;
      color: $yellow;
    }
  }
  .social {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 4px;
    background-color: $white;
    &:hover {
      transform: scale(1.1);
      transition: all .2s ease-in-out;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
  .mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .Page {
    .Footer {
      position: fixed;
      bottom: 0;
      .mobile {
        display: flex;
        justify-content: center;
      }
      .noMobile {
        display: none;
      }
    }
  }
}
