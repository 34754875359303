@import 'Variables.scss';

.Cta {
  width: 100%;
  background-color: $colorPrimary;
  padding: 5px 0;
  position: fixed;
  bottom: 0;
  .CtaItemsMenu {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .CtaItemMenu {
      width: 200px;
      margin: 10px;
      &.CtaItemsMenuStyle {
        background-color: $colorThird;
        padding: 10px;
        text-align: center;
        border-radius: 6px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    a {
      text-decoration: none;
      color: $white;
    }
  }
  &.CtaFooter {
    width: 90% !important;
    display: block;
    position: unset;
    margin: 0 auto;
  }
}
