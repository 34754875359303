@import 'Variables.scss';

.AppHeader {
  width: 100%;
  background-color: $colorPrimary;
  padding: 3vh;
  display: flex;
  position: fixed;
  top: 0;
  font-size: 15px;
  z-index: 99999;
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      font-weight: bold;
      color: $yellow;
    }
  }
  div {
    cursor: pointer;
    color: $white;
    margin: 0 10px;
    span {
      margin-left: 20px;
    }
    .hover:hover {
      font-weight: bold;
      color: $yellow;
    }
    .last {
      margin-right: 20px;
    }
  }
  .itemsMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }
  .itemsMenuMobile {
    display: none;
    .navbar-toggler {
    	// display: none;
      cursor: pointer;
    	border: 0 !important;
    	background-color: #a73972 !important;
    }

    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 5px;
      background-color: rgba(215, 126, 172, 0.6);
      margin: 6px 0;
      transition: 0.4s;
    }

    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
      background-color: rgba(215, 126, 172, 1);
    }

    .change .bar2 {opacity: 0;}

    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
      background-color: rgba(215, 126, 172, 1);
    }

    nav.open {
    	position: fixed !important;
    	height: 100vh;
      overflow: auto;
      padding-bottom: 70px;
    }
  }
  .logo {
    position: absolute;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.bannerCookie {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 999999;
  text-align: center;
  padding: 10px;
  color: $white;
  .legalLink {
    color: $colorPrimary;
    font-weight: bold;
  }
  .closeButton {
    cursor: pointer;
    width: 10px;
    height: 10px;
    padding: 10px;
    background-color: $colorPrimary
  }
}

@media only screen and (max-width: 650px) {
  .AppHeader {
    justify-content: center;
    .logo {
      right: 20%;
    }
    .itemsMenu {
      display: none;
    }
    .itemsMenuMobile {
      display: block;
      width: 100%;
    }
    div span {
      margin: 0;
    }
    .listItemMobile {
      width: 85%;
      height: 100vh;
      padding-top: 20px;
      hr {
        margin-bottom: 20px;
      }
      .sepa {
        margin: 20px 0;
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .AppHeader {
    .itemsMenu {
      margin-right: 50px;
    }
  }
}
