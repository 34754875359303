
$colorPrimary: #bf0a2b;
$colorSecondary: rgba(191,10,43,0.6);
$colorThird: rgba(255,150,150,0.7);
$white: white;
$black: black;
$error: #f30808;
$gray: #ccc;
$yellow: #fff200;
$green: #0f6d14cc;

* {
  margin: 0;
  padding: 0;
}

@font-face {
	font-family: "Comfortaa";
	src:  url(Comfortaa-Light.ttf) format("truetype");
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Comfortaa";
}

@font-face {
  font-family: 'Wide Latin';
  font-style: normal;
  font-weight: normal;
  src: local('Wide Latin'), url('LATINWD.woff') format('woff');
}

.nameSite {
  font-family: 'Wide Latin';
  text-align: center;
  color: $colorPrimary;
  // -webkit-text-stroke-width: 1px;
  // -webkit-text-stroke-color: $colorPrimary;
}

.overflow {
  overflow: hidden;
}
